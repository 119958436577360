<template>
    <!--编辑页与详情页相同-->

    <div class="ReceiveDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form :id="formId" ref="form" label-width="80px" width="auto" :inline="true" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="机构类型">
                                <el-input v-model="form.deptTypeName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="机构名称">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="内购人">
                                <el-input v-model="form.receiveName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input v-model="form.creator" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <ef-remark v-model="form.remark" readonly />
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button type="primary" @click="handleAudit" size="small"> 审核详情 </el-button>
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
                <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.stock.receive.review'" />
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.details"
                v-loading="tableLoading"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
                :id="tableId"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.bars" label="条码">
                    <template slot-scope="scope">
                        <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                    </template>
                </el-table-column>
                <el-table-column prop="fastBar" label="助记码" width="100">
                    <template slot-scope="scope">
                        {{ goodsByCode(scope.row.goodsCode).fastBar }}
                    </template>
                </el-table-column>
                <el-table-column prop="customBar" label="自编码" width="100">
                    <template slot-scope="scope">
                        {{ goodsByCode(scope.row.goodsCode).customBar }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.category" label="类目" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.unit" label="单位" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column prop="sku.brand" label="品牌" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column prop="showPrices" label="单价" width="140" v-if="showInPrice()" />
                <el-table-column prop="counts" label="内购数量" width="140" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import GoodsCommon from 'js/GoodsCommon';
import MoneyUtils from 'js/MoneyUtils';
import EfRemark from 'components/EfRemark';
import Util from 'js/Util';
import PickupReturnAudit from '../../../components/PickupReturnAudit';
export default {
    name: 'ReceiveDetail',
    components: { EfRemark, PickupReturnAudit },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: '/stock/receive',
            form: {
                code: '',
                deptCode: '',
                deptName: '',
                deptTypeName: '',
                remark: '',
                createTime: '',
                creator: '',
                receiveName: '',
                details: [],
            },
            detailParamPropertyArr: [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToThree(price);
                }),
            ],
            goodsDetailMap: new Map(),
            tableLoading: true,
            //id标识
            formId: 'form',
            tableId: 'table',
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.stockReceiveApi.detail(this.code);
            Util.copyProperties(rst, this.form);
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(rst, this.detailParamPropertyArr);
            this.form.details = arr.detailParams;
            this.goodsDetailMap = arr.goodsDetails;
            this.tableLoading = false;
        })();
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl, this.form.stockStatus);
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
